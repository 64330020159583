import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect"
import FormContato from "../components/formContato"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO title="Para Empresa - Amplie os horizontes da sua empresa" />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <header className="post-content-header">
            <h2>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 55,
                }}
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      '<span style="color: #79625d">O que você precisa?</span>'
                    )
                    .pauseFor(1500)
                    .deleteAll()
                    .typeString(
                      'RH sob <span style="color: #26BBBA">medida</span>.'
                    )
                    .changeDeleteSpeed(40)
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(
                      'Estruturando seu <span style="color: #26BBBA">RH</span>.'
                    )
                    .changeDeleteSpeed(40)
                    .pauseFor(1000)
                    .deleteAll()
                    .start()
                }}
              />
            </h2>
          </header>

          <br />
          <br></br>

          <div
            id="RhMedida"
            className="row"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="col-4" style={{ textAlign: `center` }}>
              <Img
                fixed={data.rhMedida.childImageSharp.fixed}
                className="kg-image"
              />
              <br />
              Atendimento Presencial ou Online!
              <div className="col-12" style={{ textAlign: `center` }}>
                <Link
                  to="/contato"
                  className="calltoaction-button"
                  style={{ width: `250px` }}
                >
                  Agende um bate-bapo
                </Link>
              </div>
            </div>
            <div className="col-8">
              <b style={{ fontSize: `20px`, color: `#26BBBA` }}>
                RH sob Medida pode apoiar sua empresa:
              </b>
              <br />
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Mentoria para profissionais de
                Recursos Humanos;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Projetos estratégicos
                relacionados à cultura e gestão de pessoas;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Treinamento: RH com os Valores
                1.0 e Soluções 5.0;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Desenvolvimento e mentoria para
                Líderes;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Construção das práticas de
                gestão, com soluções personalizadas, baseadas no momento e
                cultura organizacional;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Design da estratégia e práticas
                de Comunicação, Engajamento e Eventos;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Programas de Qualidade de Vida e
                Bem estar;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Apoio na estratégia, design e
                implantação dos marcos de gestão, orientados para: *valorizar a
                experiência dos(as) colaboradores(as), *fomentar uma atmosfera
                de aprendizagem e desenvolvimento, *fortalecer a cultura
                organizacional, *superar os desafios do negócio.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Assessment para processos
                seletivos, desenvolvimento e composição de times;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Formação de time multigeracional
                (programa No Age);
                <br />
              </p>
            </div>
          </div>

          <br />

          <div
            id="RhEstruturando"
            className="row"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="col-8">
              <b style={{ fontSize: `20px`, color: `#26BBBA` }}>
                Estruturando Pessoas&Cultura:
              </b>
              <br />
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consultoria especializada em
                soluções para gestão de pessoas com objetivo de apoiar líderes,
                de pequenas e médias empresas, que sabem o quanto é importante
                investir no fortalecimento da relação com o seu time e precisa
                de apoio para construção dos fundamentos da área de RH como
                estratégia de fortalecimento da cultura organizacional. 
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Soluções online, off-line e
                anywhere.
              </p>
            </div>
            <div className="col-4" style={{ textAlign: `center` }}>
              <Img
                fixed={data.rhEstruturando.childImageSharp.fixed}
                className="kg-image"
              />
              <br />
              Atendimento Presencial ou Online!
              <div className="col-12" style={{ textAlign: `center` }}>
                <Link
                  to="/contato"
                  className="calltoaction-button"
                  style={{ width: `250px` }}
                >
                  Agende um bate-bapo
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3">
              <h3>
                Alguns
                <br />
                Clientes:
              </h3>
            </div>
            <div className="col-3" style={{ textAlign: `center` }}>
              <Img
                fixed={data.logoTesa.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div className="col-3" style={{ textAlign: `center` }}>
              <Img
                fixed={data.logoJuntos.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoCplug.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoOpentech.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoAquila.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoDigitalhub.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoFerrero.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
            <div
              className="col-3"
              style={{ textAlign: `center`, verticalAlign: `middle` }}
            >
              <Img
                fixed={data.logoCarpeDiem.childImageSharp.fixed}
                className="kg-image"
              />
            </div>
          </div>
          <br />
          <div className="row" style={{ borderTop: `1px solid #000` }}>
            <h4>Leia no nosso Blog:</h4>
            {posts.map(({ node }) => {
              postCounter++
              return (
                <div className="col-12">
                  <Link to={`blog/` + node.fields.slug} key={postCounter}>
                    - {node.frontmatter.title}
                  </Link>
                </div>
              )
            })}
          </div>

          <hr />
          <h3 className="post-content-title">
            Para saber mais, me manda mensagem no formulário abaixo:
          </h3>
          <FormContato />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    rhMedida: file(relativePath: { eq: "para-empresa-rh.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rhEstruturando: file(
      relativePath: { eq: "para-empresa-estruturando.png" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoFerrero: file(relativePath: { eq: "logo-ferrero.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoCarpeDiem: file(relativePath: { eq: "logo-carpediem.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoTesa: file(relativePath: { eq: "logo-tesa.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoJuntos: file(relativePath: { eq: "logo-juntosmais.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoOpentech: file(relativePath: { eq: "logo-opentech.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoDigitalhub: file(relativePath: { eq: "logo-digitalhub.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoCplug: file(relativePath: { eq: "logo-cplug.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    logoAquila: file(relativePath: { eq: "logo-aquila.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
