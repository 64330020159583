import React from "react"
import "../utils/normalize.css"
import "../utils/css/screen.css"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function FormContato() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
          //mensagem enviada
          setState({ ...state, mensagemEnviada: true })
      })
      .catch((error) => alert(error))
  }

  if(state.mensagemEnviada){
    return(
      <>
        <br/>
        <div className="transition-fade" style={{width: `100%`, padding: "10px",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "20px",
                  verticalAlign: 'center'}}>
          
          <p>
          <b>Mensagem Enviada!</b>
          <br/>
          Obrigado pelo contato, em breve retornarei.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>


            <div className="row gtr-uniform" style={{textAlign: 'left'}}>
              <div className="col-6 col-12-xsmall">
                <label id="lblNome" alt="Nome">Nome
                <input
                  type="text"
                  name="Nome"
                  id="Nome"
                  placeholder="Qual seu nome?"
                  onChange={handleChange}
                />
                </label>
              </div>
              <div className="col-6 col-12-xsmall">
                <label id="lblEmail" alt="Email">E-mail
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  placeholder="Digite seu Email"
                  onChange={handleChange}
                />
                </label>
              </div>
              <div className="col-6 col-12-xsmall">
                <label id="lblTelefone" alt="Telefone">Telefone
                <input
                  type="text"
                  name="Telefone"
                  id="Telefone"
                  placeholder="Seu Telefone"
                  onChange={handleChange}
                />
                </label>
              </div>

              <div className="col-6 col-12-xsmall">
                <label id="lblObjetivos" alt="Objetivos">Objetivos
                <input
                  type="text"
                  name="Objetivos"
                  id="Objetivos"
                  placeholder="Qual seu Objetivo?"
                  onChange={handleChange}
                />
                </label>
              </div>

              {/* Break */}
              <div className="col-12">
                <label id="lblMensagem" alt="Mensagem">Mensagem
                <textarea
                  name="Mensagem"
                  id="Mensagem"
                  placeholder="Sobre o que você gostaria de falar?"
                  rows={6}
                  onChange={handleChange}
                />
                </label>
              </div>

              {/* Break */}
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      defaultValue="Enviar"
                      className="primary"
                      value="Enviar"
                    />
                  </li>
                </ul>
              </div>
            </div>
      </form>
    </>
  )
}
